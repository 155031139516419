import { FC, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

import React from 'react';
import {
  SerialPort,
  forgetScalePorts,
  getPreviouslyConnectedPorts,
  getScalePort,
  isWebSerialSupported,
  openScale,
} from '../shared/scale';
import { useNotifications } from '../hooks/useNotifications';
import { useAuthentication } from '../hooks/useAuthentication';

import { useTranslation } from 'react-i18next';

type TScaleConnectionMethod = null | 'webserial';

export interface ScaleContext {
  weight?: number;
  connected: boolean;
  method: TScaleConnectionMethod;
  isStable?: boolean;
  connect?: () => Promise<void>;
  forget?: () => void;
}

export const initialScaleContext = {
  connected: false,
  method: null,
};

export const scaleContext = React.createContext<ScaleContext>(initialScaleContext);

export const ScaleProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { t } = useTranslation();
  const auth = useAuthentication();

  const [value, setValue] = useState<{ isStable: boolean; weight: number } | null>(null);
  const [connected, setConnected] = useState<TScaleConnectionMethod>(null);
  const { addError, addSuccess } = useNotifications();

  const isStaffMember = auth.isStaff();

  const connect = useCallback(async () => {
    if (connected) {
      return;
    }

    if (!isWebSerialSupported()) {
      addError(t('Web serial is not supported by this browser.'));
      return;
    }

    let port: SerialPort;

    try {
      port = await getScalePort();
    } catch (error) {
      addError(t('Scale connection: {{error}}', { error }));
      console.error(error);
      return;
    }

    if (port.readable) {
      // port is already open
      return;
    }

    const info = port.getInfo();
    const usbVendorId = info.usbVendorId?.toString(16).padStart(4, '0');
    const usbProductId = info.usbProductId?.toString(16).padStart(4, '0');

    addSuccess(t('Device {{usbVendorId}}:{{usbProductId}} selected.', { usbVendorId, usbProductId }));

    port.onconnect = () => setConnected('webserial');
    port.ondisconnect = () => setConnected(null);

    openScale(port, (error, frame) => {
      if (error || !frame) {
        addError(t('Scale: {{error}}', { error }));
        console.error(error);
        return;
      }

      if (!['kg', 'g'].includes(frame.unit) || !frame.isStable) {
        return;
      }

      // sometimes the connect event is not fired
      setConnected('webserial');

      // floating Precision... e.g. try 1.005 * 1000
      const weight = frame.unit === 'kg' ? Math.round(frame.value * 1000) : frame.value;

      setValue(isFinite(weight) ? { weight, isStable: frame.isStable } : null);
    });
  }, [connected, addError, addSuccess, t]);

  useEffect(() => {
    if (connected || !isWebSerialSupported() || !isStaffMember) {
      return;
    }

    getPreviouslyConnectedPorts().then((ports) => {
      if (ports.length) {
        return connect();
      }
    });
  }, [connect, connected, isStaffMember]);

  return (
    <scaleContext.Provider
      value={{
        ...value,
        connected: !!connected,
        connect,
        method: connected,
        forget: async () => {
          await forgetScalePorts();

          setConnected(null);
        },
      }}
    >
      {children}
    </scaleContext.Provider>
  );
};

export const useScale = () => useContext<ScaleContext>(scaleContext);
