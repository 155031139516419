import React, { useEffect, useMemo } from 'react';
import 'typeface-roboto';
import 'reflect-metadata';
import { createTheme, CssBaseline, PaletteColorOptions, ThemeProvider } from '@mui/material';
import AppRouter from './AppRouter';
import { useDarkMode } from './shared/hooks/dark-mode';

import { ConfirmDialogMountPoint } from './shared/confirm-dialog';
import NewAppVersionDetector from './shared/components/NewAppVersionDetector';
import { useTranslation } from 'react-i18next';
import { Settings } from 'luxon';

const { palette } = createTheme();
const createColor = (mainColor: string, contrastText?: string) =>
  palette.augmentColor({ color: { main: mainColor, contrastText } });

const App: React.FC = () => {
  const { i18n } = useTranslation();
  const [darkMode] = useDarkMode();

  useEffect(() => {
    Settings.defaultLocale = i18n.language;
  }, [i18n.language]);

  const theme = useMemo(() => {
    const primary = createColor(darkMode ? '#7CB5E3' : '#1A294A');
    const secondary = createColor('#D51C29');

    return createTheme({
      palette: {
        mode: darkMode ? 'dark' : 'light',
        primary,
        secondary,
        printed: createColor('#42b3f5', '#ffffff'),
        outbound: createColor('#458588'),
        inbound: createColor('#b16286'),
      },
      typography: {
        h3: {
          fontSize: '1.125rem',
          fontWeight: 'bold',
        },
        h6: {
          fontSize: '1em',
          fontWeight: 'bold',
        },
        body1: {
          fontSize: '0.9rem',
        },
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
              backgroundColor: darkMode ? undefined : 'whitesmoke',
            },
            a: darkMode
              ? {
                  color: secondary.main,
                  ':visited': {
                    color: secondary.dark,
                  },
                }
              : undefined,
          },
        },
        MuiPaper: {
          variants: [
            {
              props: { variant: 'unobtrusive' },
              style: {
                border: 0,
                backgroundColor: 'transparent',
              },
            },
          ],
        },
      },
    });
  }, [darkMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppRouter />

      <ConfirmDialogMountPoint />

      <NewAppVersionDetector />
    </ThemeProvider>
  );
};

export default App;

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    unobtrusive: true;
  }
}

declare module '@mui/material/styles' {
  interface CustomPalette {
    printed: PaletteColorOptions;
    outbound: PaletteColorOptions;
    inbound: PaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    printed: true;
    outbound: true;
    inbound: true;
  }
}

declare module '@mui/material/ButtonGroup' {
  interface ButtonGroupPropsColorOverrides {
    printed: true;
    outbound: true;
    inbound: true;
  }
}

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsColorOverrides {
    printed: true;
    outbound: true;
    inbound: true;
  }
}

declare module '@mui/material/Radio' {
  interface RadioPropsColorOverrides {
    printed: true;
    outbound: true;
    inbound: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    printed: true;
    outbound: true;
    inbound: true;
  }
}

declare module '@mui/material/Avatar' {
  interface AvatarPropsColorOverrides {
    printed: true;
    outbound: true;
    inbound: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsColorOverrides {
    printed: true;
    outbound: true;
    inbound: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    printed: true;
    outbound: true;
    inbound: true;
  }
}
