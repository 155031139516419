import React, { useState } from 'react';
import { TourAndChecksDto, TourDto, TourService } from '../../../services/tour-service/tour.service';
import DataTable, { TDataTableAction, TDataTableColumn } from '../../../shared/components/data-table/DataTable';
import { Check, Clear, Person } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import TourShipmentNotificationsCell from './tour-cells/TourShipmentNotifications';
import { Alert, Avatar, Button, Stack, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import CloseOpenIconButton from '../CloseOpenIconButton';
import { useNotifications } from '../../../hooks/useNotifications';
import { IsoCountryCode, TourCustomsState, TourType } from '../../../shared/backend';
import UserLabel from '../../activity/components/UserLabel';
import CustomsStatusIcon from '../CustomsStatusIcon';
import CopyToClipboardButton from '../../../shared/components/CopyToClipboardButton';
import { DateTime } from 'luxon';
import { getShortBatchId } from '../../../shared/helper/text';
import TourWarningsCell from './tour-cells/TourWarnings';
import TourIdCell from './tour-cells/TourId';
import TourCustomerCell from './tour-cells/TourCustomer';

type Props = {
  toursAndChecks: TourAndChecksDto[];
  tourType: TourType | undefined;
  dispatchCountry: IsoCountryCode | undefined;
  destinationCountry: IsoCountryCode | undefined;
  mutate: () => Promise<void>;
};

const TourTable: React.FC<Props> = ({ toursAndChecks, tourType, dispatchCountry, destinationCountry, mutate }) => {
  const notifications = useNotifications();

  const [createdTourBatchId, setCreatedTourBatchId] = useState<string | null>(null);
  const [selectedTourIds, setSelectedTourIds] = useState<number[]>([]);

  const isSwissToGermany = dispatchCountry === IsoCountryCode.CH && destinationCountry === IsoCountryCode.DE;
  const isGermanyToSwiss = dispatchCountry === IsoCountryCode.DE && destinationCountry === IsoCountryCode.CH;
  const isGreatBritainToGermany = dispatchCountry === IsoCountryCode.GB && destinationCountry === IsoCountryCode.DE;
  const isGermanyToGreatBritain = dispatchCountry === IsoCountryCode.DE && destinationCountry === IsoCountryCode.GB;
  const isNorwayToGermany = dispatchCountry === IsoCountryCode.NO && destinationCountry === IsoCountryCode.DE;

  const hasGVMSConsignment = dispatchCountry === IsoCountryCode.GB || destinationCountry === IsoCountryCode.GB;

  const hasTransitDepartureAndTransitArrivalConsignment =
    tourType === TourType.TOUR && (isGermanyToSwiss || isGermanyToGreatBritain);

  const hasTransitAndSumAConsignment =
    (tourType === TourType.RETOUR && (isGreatBritainToGermany || isNorwayToGermany)) || isSwissToGermany;

  const hasEnsConsignment = tourType === TourType.TOUR && isGermanyToGreatBritain;

  const columns: TDataTableColumn<TourAndChecksDto>[] = [
    {
      title: 'Tour ID',
      render: TourIdCell,
      cellProps: { sx: { whiteSpace: 'nowrap' } },
    },
    {
      title: 'Notiz',
      render: ({ tour }) =>
        tour.note ? (
          <Tooltip title={tour.note}>
            <span>{tour.note.slice(0, 14)}</span>
          </Tooltip>
        ) : undefined,
    },
    {
      title: 'Kundenname',
      render: TourCustomerCell,
      cellProps: { sx: { whiteSpace: 'nowrap' } },
    },
    { title: 'Paketanzahl', render: ({ tour }) => tour.shipments.length },
    {
      title: 'Warnungen',
      render: TourWarningsCell,
    },
    {
      title: 'MRN',
      render: ({ tour }) =>
        tour.mrn ? (
          <CopyToClipboardButton
            value={tour.mrn}
            size="small"
          />
        ) : undefined,
      hidden: !isGermanyToGreatBritain,
    },
    {
      title: 'Export',
      render: ({ tour }) => {
        return <CustomsStatusIcon status={tour.exportCustomsState} />;
      },
      hidden: isNorwayToGermany,
    },
    {
      title: 'GVMS',
      render: ({ tour }) =>
        !!tour.gvmsConsignmentCreatedAt ? (
          <Tooltip title={DateTime.fromISO(tour.gvmsConsignmentCreatedAt).toString()}>
            <Check color="success" />
          </Tooltip>
        ) : (
          <Clear color="error" />
        ),
      // If the tour is exported from GB, GVMS should appear behind the Export
      hidden: !hasGVMSConsignment || dispatchCountry !== IsoCountryCode.GB,
    },
    {
      title: 'Transit',
      render: ({ tour }) => (
        <CustomsStatusIcon
          status={
            tour.transitCustomsState !== TourCustomsState.NOT_USED
              ? tour.transitCustomsState
              : tour.transitManifestCustomsState
          }
        />
      ),
      hidden: !hasTransitAndSumAConsignment,
    },
    {
      title: 'Import',
      render: ({ tour }) => <CustomsStatusIcon status={tour.importCustomsState} />,
    },

    {
      title: 'Transit-Ausgang',
      render: ({ tour }) => <CustomsStatusIcon status={tour.transitDepartureCustomsState} />,
      hidden: !hasTransitDepartureAndTransitArrivalConsignment,
    },
    {
      title: 'ENS',
      render: ({ tour }) => <CustomsStatusIcon status={tour.ensCustomsState} />,
      hidden: !hasEnsConsignment,
    },
    {
      title: 'GVMS',
      render: ({ tour }) =>
        !!tour.gvmsConsignmentCreatedAt ? (
          <Tooltip title={DateTime.fromISO(tour.gvmsConsignmentCreatedAt).toString()}>
            <Check color="success" />
          </Tooltip>
        ) : (
          <Clear color="error" />
        ),
      // If the tour is imported to GB, GVMS should appear behind the Import
      hidden: !hasGVMSConsignment || destinationCountry !== IsoCountryCode.GB,
    },
    {
      title: 'Transit-Eingang',
      render: ({ tour }) => <CustomsStatusIcon status={tour.transitArrivalCustomsState} />,
      hidden: !hasTransitDepartureAndTransitArrivalConsignment,
    },
    {
      title: 'SumA',
      render: ({ tour }) => <CustomsStatusIcon status={tour.sumACustomsState} />,
      hidden: !hasTransitAndSumAConsignment,
    },
    {
      title: 'SBS',
      render: ({ tour }) => (tour.isPartOfCollectiveReference ? <Check color="success" /> : <Clear color="error" />),
      hidden: !isGermanyToSwiss,
    },
    {
      title: 'Geschlossen',
      render: ({ tour }) => (
        <CloseOpenIconButton
          mutate={() => mutate()}
          tour={tour as TourDto}
        />
      ),
    },
    {
      title: 'Benachrichtigungen',
      render: TourShipmentNotificationsCell,
    },
    {
      title: 'Ersteller',
      render: ({ tour }) =>
        tour.createdByUserId ? (
          <UserLabel
            userId={tour.createdByUserId}
            onlyAvatar={true}
          />
        ) : (
          <Avatar key="null">
            <Person />
          </Avatar>
        ),
    },
    {
      title: 'Bearbeiter',
      render: ({ tour }) =>
        tour.assigneeUserId ? (
          <UserLabel
            userId={tour.assigneeUserId}
            onlyAvatar={true}
          />
        ) : (
          <Avatar key="null">
            <Person />
          </Avatar>
        ),
    },
  ];

  const actions: TDataTableAction<TourAndChecksDto>[] = [
    {
      icon: ({ tour }) =>
        selectedTourIds.includes(tour.tourId) ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />,
      onClick: ({ tour }) =>
        setSelectedTourIds(
          selectedTourIds.includes(tour.tourId)
            ? selectedTourIds.filter((id) => id !== tour.tourId)
            : [...selectedTourIds, tour.tourId],
        ),
      tooltip: '',
    },
  ];

  return (
    <>
      <DataTable
        actions={actions}
        columns={columns}
        rows={toursAndChecks}
        highlightPredicate={({ tour }) => (tour.process.blocked ? { bgcolor: 'error.light' } : undefined)}
        getRowKey={({ tour }) => tour.tourId}
      />

      <Stack
        direction="row"
        spacing={1}
        sx={{ marginTop: 3 }}
      >
        <Button
          variant="contained"
          disabled={selectedTourIds.length === 0}
          onClick={async () => {
            try {
              const tourBatchId = await TourService.createTourBatch(selectedTourIds);

              setCreatedTourBatchId(tourBatchId);

              notifications.addSuccess(`Neue Zwischentour erstellt.`);

              setSelectedTourIds([]);

              mutate();
            } catch (error) {
              notifications.addError(error);
            }
          }}
        >
          In neue Zwischentour verschieben
        </Button>
      </Stack>

      {createdTourBatchId && (
        <Alert
          severity="success"
          sx={{ mt: 3 }}
        >
          Touren zu neuer{' '}
          <Link to={`/customs/tour/${createdTourBatchId}`}>Zwischentour {getShortBatchId(createdTourBatchId)}</Link>{' '}
          verschoben.
        </Alert>
      )}
    </>
  );
};

export default TourTable;
