import { TourChecksDto, TourCustomsStateProperty, TourDto } from '../../../services/tour-service/tour.service';
import { CountryCode, ShipmentReturnHandling, TourCustomsState, TourType } from '../../backend';

export const MRN_PATTERN = /(\d{2})([A-Z]{2})([A-Z0-9]{12})[A-Z]\d/;
export const MRN_PATTERN_ONLY = /^(\d{2})([A-Z]{2})([A-Z0-9]{12})[A-Z]\d$/;

export const isValidMasterReferenceNumber = (mrn: string): boolean => {
  const alphabet = {
    A: 10,
    B: 12,
    C: 13,
    D: 14,
    E: 15,
    F: 16,
    G: 17,
    H: 18,
    I: 19,
    J: 20,
    K: 21,
    L: 23,
    M: 24,
    N: 25,
    O: 26,
    P: 27,
    Q: 28,
    R: 29,
    S: 30,
    T: 31,
    U: 32,
    V: 34,
    W: 35,
    X: 36,
    Y: 37,
    Z: 38,
    '0': 0,
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
  };

  if (!MRN_PATTERN_ONLY.test(mrn)) {
    return false;
  }

  const payload = mrn.split('');
  const checkDigit = parseInt(payload.pop() ?? '', 10);
  const calculatedCheckDigit =
    payload.map((c, i) => alphabet[c as keyof typeof alphabet] * Math.pow(2, i)).reduce((prev, c) => prev + c, 0) % 11;

  return checkDigit === calculatedCheckDigit;
};

export const hasCustomsState = (
  customsStateProperty: TourCustomsStateProperty,
  customsState: TourCustomsState | TourCustomsState[],
  { tour }: { tour: TourDto },
) =>
  Array.isArray(customsState)
    ? customsState.includes(tour[customsStateProperty])
    : tour[customsStateProperty] === customsState;

export const isGvmsCreated = ({ tour }: { tour: TourDto }) => !!tour.gvmsConsignmentCreatedAt;

export const getHasCustomsStatePredicate =
  (customsStateProperty: TourCustomsStateProperty, customsState: TourCustomsState | TourCustomsState[]) =>
  ({ tour }: { tour: TourDto }) =>
    hasCustomsState(customsStateProperty, customsState, { tour });

export const usesCustomsState = (customsStateProperty: TourCustomsStateProperty, { tour }: { tour: TourDto }) =>
  !hasCustomsState(customsStateProperty, TourCustomsState.NOT_USED, { tour });

export const getUsesCustomsStatePredicate =
  (customsStateProperty: TourCustomsStateProperty) =>
  ({ tour }: { tour: TourDto }) => {
    return usesCustomsState(customsStateProperty, { tour });
  };

export const getTourIdFromTour = ({ tour }: { tour: TourDto }) => tour.tourId;

export const transitDeparturePredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState('transitDepartureCustomsState', TourCustomsState.CONSIGNMENT_NOT_SENT, { tour }) &&
  hasCustomsState(
    'exportCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  ) &&
  hasCustomsState(
    'importCustomsState',
    [
      TourCustomsState.DECLARATIONS_SENT_TO_CUSTOMS,
      TourCustomsState.DECLARATIONS_ACCEPTED,
      TourCustomsState.DECLARATIONS_COMPLETE,
    ],
    { tour },
  );

export const transitArrivalPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState('transitArrivalCustomsState', TourCustomsState.CONSIGNMENT_NOT_SENT, { tour }) &&
  hasCustomsState(
    'transitDepartureCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  ) &&
  (isGbTour(tour) ? isGvmsCreated({ tour }) : true);

export const transitManifestPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'exportCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  ) && hasCustomsState('transitManifestCustomsState', TourCustomsState.CONSIGNMENT_NOT_SENT, { tour });

export const transitManifestForNorwayPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState('transitManifestCustomsState', TourCustomsState.CONSIGNMENT_NOT_SENT, { tour });

export const transitConsignmentPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'exportCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  ) && hasCustomsState('transitCustomsState', TourCustomsState.CONSIGNMENT_NOT_SENT, { tour });

export const isTransitCompletePredicate = ({ tour }: { tour: TourDto }) => {
  return (
    getHasCustomsStatePredicate('transitCustomsState', [
      TourCustomsState.DECLARATIONS_ACCEPTED,
      TourCustomsState.DECLARATIONS_COMPLETE,
    ])({ tour }) ||
    getHasCustomsStatePredicate('transitManifestCustomsState', [
      TourCustomsState.DECLARATIONS_ACCEPTED,
      TourCustomsState.DECLARATIONS_COMPLETE,
    ])({ tour })
  );
};

export const sumAPredicate = ({ tour }: { tour: TourDto }) =>
  isTransitCompletePredicate({ tour }) &&
  hasCustomsState(
    'importCustomsState',
    [
      TourCustomsState.DECLARATIONS_SENT_TO_CUSTOMS,
      TourCustomsState.DECLARATIONS_AWAITING_USER_ACTION,
      TourCustomsState.DECLARATIONS_ACCEPTED,
      TourCustomsState.DECLARATIONS_COMPLETE,
    ],
    { tour },
  ) &&
  hasCustomsState('sumACustomsState', TourCustomsState.CONSIGNMENT_NOT_SENT, { tour });

export const ensPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'transitDepartureCustomsState',
    [
      TourCustomsState.DECLARATIONS_SENT_TO_CUSTOMS,
      TourCustomsState.DECLARATIONS_AWAITING_USER_ACTION,
      TourCustomsState.DECLARATIONS_ACCEPTED,
      TourCustomsState.DECLARATIONS_COMPLETE,
    ],
    { tour },
  ) && hasCustomsState('ensCustomsState', TourCustomsState.CONSIGNMENT_NOT_SENT, { tour });

export const importGvmsPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'importCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  ) &&
  hasCustomsState(
    'ensCustomsState',
    [
      TourCustomsState.DECLARATIONS_SENT_TO_CUSTOMS,
      TourCustomsState.DECLARATIONS_AWAITING_USER_ACTION,
      TourCustomsState.DECLARATIONS_ACCEPTED,
      TourCustomsState.DECLARATIONS_COMPLETE,
    ],
    {
      tour,
    },
  );

export const exportGvmsPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'exportCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  );

export const collectiveReferencePredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'exportCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  ) &&
  hasCustomsState(
    'importCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  );

export const transitDepartureDocumentPredicate = ({ tour }: { tour: TourDto }) =>
  hasCustomsState(
    'transitDepartureCustomsState',
    [TourCustomsState.DECLARATIONS_ACCEPTED, TourCustomsState.DECLARATIONS_COMPLETE],
    { tour },
  );

export const hasEmptyTourPredicate = ({ checks, tour }: { checks: TourChecksDto; tour: TourDto }): boolean =>
  checks.emptyShipments.length >= tour.shipments.length;

export const hasMissingShippingWeightsPredicate = ({ checks }: { checks: TourChecksDto }): boolean =>
  !!checks.missingShipmentWeights.length;

export const hasMissingArticlePredicate = ({ checks }: { checks: TourChecksDto }): boolean =>
  !!checks.missingArticles.length;

export const hasBlockedArticlesPredicate = ({ checks }: { checks: TourChecksDto }): boolean =>
  !!checks.blockedArticleIds.length;

export const hasIncompleteArticleIdsPredicate = ({ checks }: { checks: TourChecksDto }): boolean =>
  !!checks.incompleteArticleValues.length;

export const hasInvalidArticleIdsPredicate = ({ checks }: { checks: TourChecksDto }): boolean =>
  !!checks.invalidArticleValues.length;

export const hasMissingExportoBarcodePredicate = ({ checks }: { checks: TourChecksDto }): boolean =>
  !!checks.missingExportoBarcode.length;

export const hasProcessWithoutClosedTourPredicate = ({ checks }: { checks: TourChecksDto }): boolean =>
  !checks.hasClosedTourForProcess;

export const hasShipmentsWithEmkPredicate = ({ checks }: { checks: TourChecksDto }): boolean =>
  !!checks.shipmentsWithEmk.length;

export const hasSpecialTreatmentPredicate = ({ tour }: { tour: TourDto }): boolean =>
  tour.type === TourType.RETOUR && tour.process.returnPackageHandling === ShipmentReturnHandling.SPECIAL_TREATMENT;

export const isShipoutAllowedPredicate = ({ tour }: { tour: TourDto }): boolean =>
  hasCustomsState('importCustomsState', [TourCustomsState.DECLARATIONS_COMPLETE], { tour });

const isGbTour = (tour: TourDto): boolean =>
  tour.type === TourType.TOUR &&
  tour.process.processType.dispatchCountry === CountryCode.DE &&
  tour.process.processType.destinationCountry === CountryCode.GB;
