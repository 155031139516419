import React, { useState } from 'react';
import { useNotifications } from '../../../hooks/useNotifications';
import { TourService } from '../../../services/tour-service/tour.service';
import { CircularProgress, IconButton } from '@mui/material';
import { LockOpen } from '@mui/icons-material';

type Props = {
  batchId: string;
};

const CloseAllToursIconButton: React.FC<Props> = ({ batchId }) => {
  const notificationHandler = useNotifications();

  const batch = TourService.useTourBatch(batchId);
  const tours = TourService.useToursByBatch(batchId);

  const [isLoading, setLoading] = useState<boolean>(false);

  const handleCloseAllTours = async () => {
    setLoading(true);

    try {
      await TourService.closeTourBatch(batchId);

      await batch.mutate();
      await tours.mutate();

      notificationHandler.addInfo('Touren wurden erfolgreich geschlossen');
    } catch (error) {
      notificationHandler.addError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <IconButton
      onClick={() => handleCloseAllTours()}
      color="secondary"
      disabled={isLoading}
    >
      {isLoading ? <CircularProgress size="1em" /> : <LockOpen />}
    </IconButton>
  );
};

export default CloseAllToursIconButton;
