import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useNotifications } from '../../hooks/useNotifications';
import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  LinearProgress,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import {
  ConsignmentPartyDto,
  ConsignmentVehicleIdentificationDto,
  CustomsService,
  IAdditionalConsignmentData,
} from '../../services/customs-service/customs.service';
import { IPersonInCharge } from '../../services/customs-service/customs.types';
import { ConsignmentType, IsoCountryCode } from '../../shared/backend';
import SelectWithFilter from '../../shared/components/SelectWithFilter';
import { useUserInfo } from '../../services/user-service/user.service';
import { isValidMasterReferenceNumber, MRN_PATTERN } from '../../shared/helper/customs/customs-helper';
import { useTranslation } from 'react-i18next';
import TourNotesAlert from './TourNotesAlert';

const PERSON_IN_CHARGE = 'PERSON_IN_CHARGE';
const CONSIGNMENT_CARRIER = 'CONSIGNMENT_CARRIER';
const TRANSIT_CONSIGNOR = 'TRANSIT_CONSIGNOR';
const SHIPPING_POINT = 'SHIPPING_POINT';

export interface CreateConsignmentModalProps {
  open: boolean;
  tourIds: number[] | undefined;
  notes: string[];

  consignmentType: ConsignmentType | undefined;
  tourDispatchCountry: IsoCountryCode;

  onClose: () => void;
  createConsignments: (additionalConsignmentData: IAdditionalConsignmentData) => Promise<void>;
}

const CreateConsignmentModal: React.FC<CreateConsignmentModalProps> = ({
  open,
  tourIds,
  notes,
  consignmentType,
  tourDispatchCountry,
  onClose,
  createConsignments,
}) => {
  const { t } = useTranslation('customs');
  const userInfo = useUserInfo();
  const notificationHandler = useNotifications();

  const personsInCharge = CustomsService.usePersonsInCharge();
  const vehicleIdentifications = CustomsService.useVehicleIdentifications();
  const carrierIdentifications = CustomsService.useConsignmentParties({ isCarrier: true });
  const transitConsignors = CustomsService.useConsignmentParties({ isTransitConsignor: true });
  const shippingPoints = CustomsService.useConsignmentParties({ isShippingPoint: true });

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [personInCharge, setPersonInCharge] = useState<IPersonInCharge>();
  const [vehicleIdentification, setVehicleIdentification] = useState<ConsignmentVehicleIdentificationDto>();
  const [consignmentCarrier, setConsignmentCarrier] = useState<ConsignmentPartyDto>();
  const [consignmentTransitConsignor, setConsignmentTransitConsignor] = useState<ConsignmentPartyDto>();
  const [consignmentShippingPoint, setConsignmentShippingPoint] = useState<ConsignmentPartyDto>();
  const [customsRegistrationNumber, setCustomsRegistrationNumber] = useState<string | undefined>();

  const areExportGbConsignments =
    consignmentType === ConsignmentType.EXPORT && tourDispatchCountry === IsoCountryCode.GB;

  const areExportDeConsignments =
    consignmentType === ConsignmentType.EXPORT && tourDispatchCountry === IsoCountryCode.DE;

  const areImportNoConsignments = consignmentType === ConsignmentType.POST_NORD_IMPORT;

  const showPersonInCharge = !areImportNoConsignments;
  const showVehicleIdentification = !areImportNoConsignments;

  const isNoTransitManifestConsignment =
    consignmentType === ConsignmentType.TRANSIT_MANIFEST && tourDispatchCountry === IsoCountryCode.NO;

  const showCarrier = areExportGbConsignments || areExportDeConsignments;
  const showTransitConsignor =
    !!consignmentType && [ConsignmentType.TRANSIT_DEPARTURE, ConsignmentType.ENS].includes(consignmentType);
  const showShippingPoint = areExportDeConsignments;

  const resetModal = () => {
    setLoading(false);
    setSuccess(false);
    setCustomsRegistrationNumber(undefined);
  };

  const handleClose = () => {
    onClose();
    resetModal();
  };

  const personInChargeByUser = personsInCharge.data.find(
    (person) => person.email.toLowerCase().trim() === userInfo.data?.email.toLowerCase().trim(),
  );

  useEffect(() => {
    setPersonInCharge(personInChargeByUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personInChargeByUser?.consignmentPersonInChargeId]);

  const handleDropdownValueChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { target } = event;

    switch (target.name) {
      case PERSON_IN_CHARGE:
        const personInCharge = personsInCharge.data.find((personInCharge) => personInCharge.email === target.value);
        if (personInCharge) {
          setPersonInCharge(personInCharge);
        }
        break;
      case CONSIGNMENT_CARRIER:
        const consignmentCarrier = carrierIdentifications.data.find(
          (consignmentCarrier) => consignmentCarrier.consignmentPartyId === Number(target.value),
        );
        if (consignmentCarrier) {
          setConsignmentCarrier(consignmentCarrier);
        }
        break;
      case TRANSIT_CONSIGNOR:
        const consignmentTransitConsignor = transitConsignors.data.find(
          (consignmentTransitConsignor) => consignmentTransitConsignor.consignmentPartyId === Number(target.value),
        );
        if (consignmentTransitConsignor) {
          setConsignmentTransitConsignor(consignmentTransitConsignor);
        }
        break;
      case SHIPPING_POINT:
        const consignmentShippingPoint = shippingPoints.data.find(
          (consignmentShippingPoint) => consignmentShippingPoint.consignmentPartyId === Number(target.value),
        );
        if (consignmentShippingPoint) {
          setConsignmentShippingPoint(consignmentShippingPoint);
        }
        break;
      default:
    }
  };

  const handleCreateConsignments = async () => {
    if ((personInCharge && vehicleIdentification) || areImportNoConsignments) {
      setLoading(true);

      try {
        await createConsignments({
          personInChargeId: personInCharge?.consignmentPersonInChargeId,
          vehicleIdentificationId: vehicleIdentification?.consignmentVehicleIdentificationId,
          carrierId: consignmentCarrier?.consignmentPartyId,
          transitConsignorId: consignmentTransitConsignor?.consignmentPartyId,
          shippingPointId: consignmentShippingPoint?.consignmentPartyId,
          customsRegistrationNumber,
        });

        setSuccess(true);
        handleClose();
      } catch (error) {
        notificationHandler.addError(error);
        handleClose();
      }

      setLoading(false);
    }
  };

  const mrnsInNotes = [
    ...new Set(notes.flatMap((note) => note.match(new RegExp(MRN_PATTERN, 'g'))).filter((mrn): mrn is string => !!mrn)),
  ];
  const mrnInNotes = mrnsInNotes.length === 1 ? mrnsInNotes[0] : undefined;
  const hasMultipleMrnsForNo = mrnsInNotes.length > 1 && isNoTransitManifestConsignment;
  const customsRegistrationNumberError =
    !!customsRegistrationNumber && !MRN_PATTERN.test(customsRegistrationNumber)
      ? t('MRN format is invalid.')
      : !!customsRegistrationNumber && !isValidMasterReferenceNumber(customsRegistrationNumber)
        ? t('Check digit is invalid.')
        : undefined;

  useEffect(() => {
    if (mrnInNotes && typeof customsRegistrationNumber === 'undefined' && isNoTransitManifestConsignment) {
      setCustomsRegistrationNumber(mrnInNotes);
    }
  }, [customsRegistrationNumber, mrnInNotes, isNoTransitManifestConsignment]);

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle>Consignment erstellen ({consignmentType})</DialogTitle>

      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (!loading) {
            handleCreateConsignments();
          }
        }}
      >
        <DialogContent>
          <DialogContentText gutterBottom>
            {areImportNoConsignments
              ? 'Möchtest du Import-Deklarationen für die folgenden Touren bei Post-Nord erstellen? '
              : 'Möchtest du ein Consignment für die folgenden Touren bei AEB erstellen?'}
          </DialogContentText>

          <DialogContentText>{tourIds?.join(', ')}</DialogContentText>

          <Stack
            direction="column"
            spacing={2}
            my={3}
          >
            {showPersonInCharge && (
              <FormControl variant="outlined">
                <TextField
                  select
                  name={PERSON_IN_CHARGE}
                  value={personInCharge?.email ?? ''}
                  onChange={handleDropdownValueChange}
                  disabled={personsInCharge.isLoading}
                  label="Verantwortliche Person"
                  required
                >
                  {personsInCharge.data
                    .sort((a, b) => a.surname.localeCompare(b.surname))
                    .map((personInCharge) => (
                      <MenuItem
                        key={personInCharge.email}
                        value={personInCharge.email}
                      >{`${personInCharge.surname}, ${personInCharge.forename}`}</MenuItem>
                    ))}
                </TextField>
                {personsInCharge.isLoading && <LinearProgress />}
              </FormControl>
            )}

            {showVehicleIdentification && (
              <FormControl variant="outlined">
                <SelectWithFilter
                  values={vehicleIdentifications.data
                    .sort((a, b) => a.identification.localeCompare(b.identification))
                    .map((vehicleIdentification) => ({
                      id: vehicleIdentification.identification,
                      label: vehicleIdentification.identification,
                    }))}
                  selectedValueIds={[vehicleIdentification?.identification ?? '']}
                  onChange={([identification]) => {
                    if (!identification) {
                      // selection was cleared
                      setVehicleIdentification(undefined);
                      return;
                    }

                    const vehicleIdentification = vehicleIdentifications.data.find(
                      (vehicleIdentification) => vehicleIdentification.identification === identification,
                    );

                    if (vehicleIdentification) {
                      setVehicleIdentification(vehicleIdentification);
                    }
                  }}
                  label="Fahrzeugkennzeichen"
                  required
                />
                {vehicleIdentifications.isLoading && <LinearProgress />}
              </FormControl>
            )}

            {showCarrier && (
              <FormControl variant="outlined">
                <TextField
                  select
                  name={CONSIGNMENT_CARRIER}
                  value={consignmentCarrier?.consignmentPartyId ?? ''}
                  onChange={handleDropdownValueChange}
                  disabled={carrierIdentifications.isLoading}
                  label="Carrier"
                  required
                >
                  {carrierIdentifications.data
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((consignmentCarrier) => (
                      <MenuItem
                        key={consignmentCarrier.name}
                        value={consignmentCarrier.consignmentPartyId}
                      >
                        {consignmentCarrier.name}
                      </MenuItem>
                    ))}
                </TextField>
                {carrierIdentifications.isLoading && <LinearProgress />}
              </FormControl>
            )}

            {showTransitConsignor && (
              <FormControl variant="outlined">
                <TextField
                  select
                  name={TRANSIT_CONSIGNOR}
                  value={consignmentTransitConsignor?.consignmentPartyId ?? ''}
                  onChange={handleDropdownValueChange}
                  disabled={transitConsignors.isLoading}
                  label="Transit-Anmelder"
                  required
                >
                  {transitConsignors.data
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((consignmentTransitConsignor) => (
                      <MenuItem
                        key={consignmentTransitConsignor.name}
                        value={consignmentTransitConsignor.consignmentPartyId}
                      >
                        {consignmentTransitConsignor.name}
                      </MenuItem>
                    ))}
                </TextField>
                {transitConsignors.isLoading && <LinearProgress />}
              </FormControl>
            )}

            {showShippingPoint && (
              <FormControl variant="outlined">
                <TextField
                  select
                  name={SHIPPING_POINT}
                  value={consignmentShippingPoint?.consignmentPartyId ?? ''}
                  onChange={handleDropdownValueChange}
                  disabled={shippingPoints.isLoading}
                  label="Abgangsort"
                  required
                >
                  {shippingPoints.data
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((consignmentShippingPoint) => (
                      <MenuItem
                        key={consignmentShippingPoint.name}
                        value={consignmentShippingPoint.consignmentPartyId}
                      >
                        {consignmentShippingPoint.name}
                      </MenuItem>
                    ))}
                </TextField>
                {shippingPoints.isLoading && <LinearProgress />}
              </FormControl>
            )}

            {isNoTransitManifestConsignment && (
              <FormControl variant="outlined">
                <TextField
                  value={customsRegistrationNumber ?? ''}
                  onChange={(event) => setCustomsRegistrationNumber(event.target.value)}
                  label={t('Transit-MRN (creates manual transit manifest consignment)')}
                  error={!!customsRegistrationNumberError}
                  helperText={customsRegistrationNumberError}
                ></TextField>
              </FormControl>
            )}
          </Stack>

          <Stack
            direction="column"
            spacing={1}
          >
            {notes.filter((n) => !!n).length > 0 && <TourNotesAlert notes={notes} />}

            {hasMultipleMrnsForNo && (
              <Alert severity="error">
                <AlertTitle>{t('We found multiple MRN')}</AlertTitle>

                {t(
                  'A transit manifest consignment can only be created for one MRN at a time. Please split the tour batch so that every tour batch contains exactly one MRN.',
                )}
              </Alert>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            disabled={
              success ||
              loading ||
              (areImportNoConsignments ? false : !personInCharge || !vehicleIdentification) ||
              hasMultipleMrnsForNo
            }
            startIcon={
              loading ? (
                <CircularProgress
                  color="primary"
                  size="1em"
                />
              ) : undefined
            }
          >
            Consignment erstellen
          </Button>
          <Button
            onClick={() => handleClose()}
            variant="contained"
            color="primary"
          >
            {success ? 'Schließen' : 'Abbrechen'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateConsignmentModal;
