import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { useNotifications } from '../../../../../hooks/useNotifications';
import * as CarrierOptionService from '../../../../../services/carrier-option-service/carrierOption.service';
import * as ShipmentMethodService from '../../../../../services/shipment-method-service/shipmentMethod.service';
import CarrierOptionsView from './carrier-options-view/carrierOptionsView';
import DeleteButton from '../../../../../shared/components/delete/DeleteButton';
import CredentialEditor from './CredentialEditor';
import { LoadingButton } from '@mui/lab';
import { RestoreFromTrash, Save } from '@mui/icons-material';
import { Direction, ShipmentMethodCarrierName, TourType } from '../../../../../shared/backend';
import TourTypeIcon from '../../../../customs/TourTypeIcon';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

export interface ShipmentMethodCardProps {
  shipmentMethod: ShipmentMethodService.ShipmentMethodDto;
  onShipmentMethodUpdate: (
    body: Partial<ShipmentMethodService.ShipmentMethodDto>,
    shipmentMethodId: number,
    processId: number,
  ) => Promise<ShipmentMethodService.ShipmentMethodDto | undefined>;
  onShipmentMethodDelete: (shipmentMethodId: number, processId: number) => Promise<true | undefined>;
  onShipmentMethodRestore: (
    shipmentMethodId: number,
    processId: number,
  ) => Promise<ShipmentMethodService.ShipmentMethodDto | undefined>;
  availableCarriers: string[] | undefined;
  processId: number;
}

const ShipmentMethodCard: FC<ShipmentMethodCardProps> = (props: ShipmentMethodCardProps) => {
  const { t } = useTranslation('CRM');
  const notificationHandler = useNotifications();
  const [isSaving, setSaving] = useState(false);

  const [shipmentMethodNameFormField, setShipmentMethodNameFormField] = useState(props.shipmentMethod.methodName);
  const [shipmentMethodIsExternalFormField, setShipmentMethodIsExternalFormField] = useState(
    props.shipmentMethod.isExternal,
  );
  const [carrierCredentialFormField, setCarrierCredentialFormField] = useState<Record<string, unknown>>(
    props.shipmentMethod.carrierCredential ?? {},
  );
  const [directionFormField, setDirectionFormField] = useState(props.shipmentMethod.direction ?? null);
  const [selectedCarrier, setSelectedCarrier] = useState(props.shipmentMethod.carrierName);

  const credentialSchema = CarrierOptionService.useCarrierCredentialSchemaByCarrierName(selectedCarrier);

  const availableCarrierOptions = CarrierOptionService.useCarrierOptionsByCarrierName(selectedCarrier);
  const carrierOptions = CarrierOptionService.useCarrierOptionsByProcessAndShipmentMethod(
    props.shipmentMethod.processId,
    props.shipmentMethod.shipmentMethodId,
  );

  const updateShipmentMethod = async () => {
    setSaving(true);

    let partialShipmentMethod: ShipmentMethodService.UpdateShipmentMethodDto = {
      carrierCredential: carrierCredentialFormField,
      methodName: shipmentMethodNameFormField,
      direction: directionFormField,
      isExternal: shipmentMethodIsExternalFormField,
    };

    await props.onShipmentMethodUpdate(partialShipmentMethod, props.shipmentMethod.shipmentMethodId, props.processId);
    setTimeout(() => setSaving(false), 1000);
  };

  const handleCarrierOptionCreate = async (
    body: CarrierOptionService.CreateCarrierOptionDto,
  ): Promise<CarrierOptionService.CarrierOptionDto | undefined> => {
    const newCarrierOption = await createCarrierOption(body);
    if (newCarrierOption) {
      await carrierOptions.mutate();

      return newCarrierOption;
    }
  };

  const handleCarrierOptionDelete = async (carrierOptionId: number): Promise<true | undefined> => {
    const deletedCarrierOption = await deleteCarrierOption(carrierOptionId);
    if (deletedCarrierOption) {
      await carrierOptions.mutate();

      return true;
    }
  };

  const handleCarrierChange = async (newSelectedCarrier: ShipmentMethodCarrierName) => {
    const prevSelectedCarrier = selectedCarrier;
    setSelectedCarrier(newSelectedCarrier);

    let newShipmentMethod = await props.onShipmentMethodUpdate(
      {
        carrierName: newSelectedCarrier,
      },
      props.shipmentMethod.shipmentMethodId,
      props.processId,
    );

    //FIXME: Check if selected Carrier matches the response carrier
    //        if not --> go back to previous selection
    if (!newShipmentMethod) {
      setSelectedCarrier(prevSelectedCarrier);
    }
  };

  const createCarrierOption = async (
    body: CarrierOptionService.CreateCarrierOptionDto,
  ): Promise<CarrierOptionService.CarrierOptionDto | undefined> => {
    try {
      return await CarrierOptionService.postCarrierOption(
        body,
        props.shipmentMethod.processId,
        props.shipmentMethod.shipmentMethodId,
      );
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  const handleShipmentMethodDelete = () => {
    props.onShipmentMethodDelete(props.shipmentMethod.shipmentMethodId, props.processId);
  };

  const deleteCarrierOption = async (carrierOptionId: number): Promise<true | undefined> => {
    try {
      await CarrierOptionService.deleteCarrierOption(
        props.shipmentMethod.processId,
        props.shipmentMethod.shipmentMethodId,
        carrierOptionId,
      );
      return true;
    } catch (error) {
      notificationHandler.addError(error);
    }
  };

  const hasFormChanges = (): boolean => {
    const credentialKeys = credentialSchema.data?.properties ? Object.keys(credentialSchema.data.properties) : [];
    const hasCredentialChanges = !!credentialKeys.find(
      (key) => props.shipmentMethod.carrierCredential?.[key] !== carrierCredentialFormField[key],
    );
    // Keys that are not in credential schema but were updated
    const hasUnknownCredentialFieldChanges = !!Object.entries(carrierCredentialFormField).find(
      ([key, value]) => !credentialKeys.includes(key) && props.shipmentMethod.carrierCredential?.[key] !== value,
    );
    const hasNameChanges = shipmentMethodNameFormField !== props.shipmentMethod.methodName;
    const hasIsExternalChanges = shipmentMethodIsExternalFormField !== props.shipmentMethod.isExternal;
    const hasDirectionChanges = directionFormField !== (props.shipmentMethod.direction ?? null);

    return (
      hasCredentialChanges ||
      hasNameChanges ||
      hasIsExternalChanges ||
      hasUnknownCredentialFieldChanges ||
      hasDirectionChanges
    );
  };

  const availableCarriers = props.availableCarriers?.length ? props.availableCarriers : [selectedCarrier];
  const shipmentMethodId = props.shipmentMethod.shipmentMethodId;

  return (
    <Stack
      direction="column"
      spacing={1}
    >
      <Grid
        container
        spacing={1}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          xl={2}
        >
          <TextField
            value={props.shipmentMethod.shipmentMethodId}
            label={t('Method Id')}
            aria-readonly
            disabled
            fullWidth
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          xl={2}
        >
          <TextField
            value={props.shipmentMethod.counter}
            label={t('Counter')}
            aria-readonly
            disabled
            fullWidth
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          xl={2}
        >
          <TextField
            variant="outlined"
            label={t('Shipping service provider')}
            value={selectedCarrier}
            select={true}
            onChange={(event) => handleCarrierChange(event.target.value as ShipmentMethodCarrierName)}
            disabled
            fullWidth
          >
            {availableCarriers.map((carrier) => (
              <MenuItem
                value={carrier}
                key={carrier}
              >
                {carrier}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          xl={2}
        >
          <TextField
            value={shipmentMethodNameFormField}
            onChange={(event) => setShipmentMethodNameFormField(event.target.value)}
            label={t('Name')}
            disabled={!!props.shipmentMethod.deletedAt}
            fullWidth
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          xl={2}
        >
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                checked={shipmentMethodIsExternalFormField}
                onChange={(ev) => setShipmentMethodIsExternalFormField(ev.target.checked)}
                disabled={!!props.shipmentMethod.deletedAt}
              />
            }
            label={t('External usage')}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          xl={2}
        >
          <TextField
            variant="outlined"
            select={true}
            required={false}
            label={t('Direction')}
            onChange={(event) => setDirectionFormField((event.target.value as Direction) ?? null)}
            value={directionFormField}
            disabled={!!props.shipmentMethod.deletedAt}
            fullWidth
          >
            {Object.values(Direction).map((option) => (
              <MenuItem value={option}>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  height="20px"
                >
                  <TourTypeIcon
                    sx={{ height: 20, width: 20 }}
                    tourType={option === Direction.INBOUND ? TourType.RETOUR : TourType.TOUR}
                  />
                  <Typography sx={{ margin: 0, textTransform: 'capitalize' }}>{option}</Typography>
                </Stack>
              </MenuItem>
            ))}

            <MenuItem value={undefined}>{t('Both')}</MenuItem>
          </TextField>
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          xl={2}
        >
          <CarrierOptionsView
            carrierOptions={carrierOptions.data ?? []}
            availableCarrierOptions={availableCarrierOptions.data ?? []}
            onCarrierOptionDelete={handleCarrierOptionDelete}
            onCarrierOptionCreate={handleCarrierOptionCreate}
            disabled={!!props.shipmentMethod.deletedAt}
          />
        </Grid>

        {credentialSchema.data === null && (
          <Grid item>
            <Alert severity="warning">
              <AlertTitle>{t('Credential schema not available')}</AlertTitle>
              {t('Is the label microservice running?')}
            </Alert>
          </Grid>
        )}

        {credentialSchema.isLoading ? (
          <CircularProgress size="1em" />
        ) : (
          credentialSchema.data && (
            <CredentialEditor
              credentialSchema={credentialSchema.data}
              credential={carrierCredentialFormField}
              onCredentialChange={async (value) => {
                setCarrierCredentialFormField(value);
                return true;
              }}
              disabled={!!props.shipmentMethod.deletedAt}
            ></CredentialEditor>
          )
        )}
      </Grid>

      <Stack
        direction="row"
        spacing={1}
        justifyContent="flex-end"
        alignItems="center"
      >
        {!props.shipmentMethod.deletedAt && (
          <>
            <LoadingButton
              disabled={!hasFormChanges()}
              onClick={() => updateShipmentMethod()}
              variant="contained"
              color="secondary"
              loading={isSaving}
              startIcon={<Save />}
            >
              {t('Update Method {{shipmentMethodId}}', { shipmentMethodId })}
            </LoadingButton>

            <DeleteButton
              label={t('Delete id {{shipmentMethodId}}', { shipmentMethodId })}
              variant="contained"
              onClick={handleShipmentMethodDelete}
            />
          </>
        )}

        {!!props.shipmentMethod.deletedAt && (
          <>
            <Typography>Gelöscht {DateTime.fromISO(props.shipmentMethod.deletedAt).toRelative()}</Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<RestoreFromTrash />}
              onClick={() => {
                props.onShipmentMethodRestore(props.shipmentMethod.shipmentMethodId, props.processId);
              }}
            >
              {t('Restore Method {{shipmentMethodId}}', { shipmentMethodId })}
            </Button>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default ShipmentMethodCard;
