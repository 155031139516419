import { Button, Skeleton } from '@mui/material';
import React, { useState } from 'react';
import DiamondIcon from '@mui/icons-material/Diamond';
import SendIcon from '@mui/icons-material/Send';
import GavelIcon from '@mui/icons-material/Gavel';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CreateConsignmentDialog from './CreateConsignmentDialog';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { ConsignmentState } from '@exporto/types-customs';
import { IAdditionalConsignmentData, CustomsService } from '../../services/customs-service/customs.service';
import { useNotifications } from '../../hooks/useNotifications';
import { getJobResultFileName, JobError, jobService } from '../../services/job-service/job.service';
import fileDownload from 'js-file-download';
import { TourService } from '../../services/tour-service/tour.service';
import { ConsignmentType, IsoCountryCode, TourType } from '../../shared/backend';
import ButtonAsync from '../../shared/components/ButtonAsync';
import { pdfFileDownload } from '../../shared/helper/pdfFileDownload';
import DeImportDocumentsDialog from '../../shared/components/de-import-documents-dialog/DeImportDocumentsDialog';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import { ProcessService } from '../../services/process-service/process.service';

type Props = {
  batch: {
    tourBatchId: string | null;
    dispatchCountry: IsoCountryCode;
    destinationCountry: IsoCountryCode;
    notes: string[];
  };
  isSingleTour?: boolean; // Whether this contains actions for a single tour or a whole batch
  isLoading: boolean;
  type: TourType;
  deImportTourIds?: number[];
  importTourIds?: number[];
  exportTourIds?: number[];
  exportGvmsTourIds?: number[];
  importGvmsTourIds?: number[];
  transitTourIds?: number[];
  transitManifestTourIds?: number[];
  transitDepartureTourIds?: number[];
  transitArrivalTourIds?: number[];
  tourIdsWithEmk?: number[];
  tourIdsForCollectiveReference?: number[];
  tourIdsForTransitDepartureDocument?: number[];
  sumATourIds?: number[];
  ensTourIds?: number[];
};

const CustomsActions: React.FC<Props> = ({
  batch,
  isSingleTour,
  isLoading,
  type,
  deImportTourIds,
  importTourIds,
  exportTourIds,
  exportGvmsTourIds,
  importGvmsTourIds,
  transitTourIds,
  transitManifestTourIds,
  transitDepartureTourIds,
  transitArrivalTourIds,
  tourIdsWithEmk,
  tourIdsForCollectiveReference,
  tourIdsForTransitDepartureDocument,
  sumATourIds,
  ensTourIds,
}) => {
  const { tourBatchId } = batch;
  const { t } = useTranslation();
  const notifications = useNotifications();
  const { data, mutate } = TourService.useToursByBatch(tourBatchId);

  const [dialog, setDialog] = useState<{
    tourIds: number[];
    consignmentType: ConsignmentType;
  } | null>(null);
  const [showDeImportModal, setShowDeImportModal] = useState(false);
  const [postNordZipProgress, setPostNordZipProgress] = useState(0);
  const [batchInvoiceProgress, setBatchInvoiceProgress] = useState(0);

  const tourDispatchCountry = type === TourType.TOUR ? batch.dispatchCountry : batch.destinationCountry;
  const isReturnFromGb = type === TourType.RETOUR && batch.destinationCountry === IsoCountryCode.GB;
  const isNorwayProcessType = batch.destinationCountry === IsoCountryCode.NO;
  const isReturnFromNorway = type === TourType.RETOUR && isNorwayProcessType;

  const openDialog = (tourIds: number[], consignmentType: ConsignmentType) => setDialog({ tourIds, consignmentType });

  const waitForConsignmentJob = async (jobId: string, tourIds: number[]) => {
    try {
      const jobObject = await jobService.addJobPromise(jobId);

      if (!jobObject.returnvalue) {
        return;
      }

      const errorMessages: { tourId: number; message: string | undefined }[] = [];
      const successStates: { tourId: number; consignmentState: ConsignmentState }[] = [];

      for (const tourId of tourIds) {
        const tourResult = jobObject.returnvalue.result?.find((result) => result.tourIds?.find((id) => tourId === id));

        const consignmentState = tourResult?.state ?? ConsignmentState.NOT_CREATED;

        if (consignmentState === ConsignmentState.ERROR || consignmentState === ConsignmentState.CREATED_WITH_ERRORS) {
          errorMessages.push({ tourId, message: tourResult?.message });
        } else {
          successStates.push({ tourId, consignmentState });
        }
      }

      const successTours = successStates.filter(
        ({ consignmentState }) => consignmentState === ConsignmentState.SUCCESS,
      );
      const otherTours = successStates.filter(({ consignmentState }) => consignmentState !== ConsignmentState.SUCCESS);

      if (successTours.length) {
        notifications.addSuccess(
          `Consignments für die Touren ${successTours
            .map(({ tourId }) => tourId)
            .join(', ')} wurden erfolgreich erstellt.`,
        );
      }

      if (otherTours.length) {
        notifications.addInfo(
          `Consignments für die Touren ${otherTours
            .map(({ tourId }) => tourId)
            .join(', ')} wurden mit folgenden Stati erstellt:\n${otherTours
            .map(({ tourId, consignmentState }) => `${tourId}: ${consignmentState}`)
            .join('\n')}`,
        );
      }

      if (errorMessages.length) {
        for (const { tourId, message } of errorMessages) {
          notifications.addError(
            `Fehler beim Erstellen der Consignments für die Tour ${tourId}${message ? `:\n${message}` : ''}`,
          );
        }
      }
    } catch (error) {
      if (error instanceof JobError) {
        notifications.addError(
          `Fehler beim Erstellen der Consignments für die Touren ${tourIds.join(', ')}: ${
            error.getJobState().failedReason
          }`,
        );
      } else {
        notifications.addError(error);
      }
    } finally {
      mutate();
    }
  };

  const handleCreateTourBatchInvoices = async (tourBatchId: string) => {
    const jobData = await CustomsService.createTourBatchInvoices({ tourBatchId });

    try {
      const jobObject = await jobService.addJobPromise(jobData.jobId.toString(), (_, { progress }) =>
        setBatchInvoiceProgress(progress * 100),
      );
      const binaryBuffer = jobObject.returnvalue.file;

      if (binaryBuffer) {
        notifications.addSuccess(
          `Rechnungen für Tour Batch ${tourBatchId} wurden erfolgreich erstellt und werden heruntergeladen.`,
        );
        const zipBuffer = Buffer.from(binaryBuffer, 'binary');
        fileDownload(zipBuffer, getJobResultFileName(jobObject), `application/zip`);
      } else {
        notifications.addError(`Dokument aus Job ${jobData.jobId} konnte nicht heruntergeladen werden`);
      }

      await mutate();
    } catch (error) {
      if (error instanceof JobError) {
        notifications.addError(
          `Fehler beim Erstellen der Rechnungen für Tour Batch ${tourBatchId}: ${error.getJobState().failedReason}`,
        );
      } else {
        notifications.addError(error);
      }
    } finally {
      setBatchInvoiceProgress(0);
    }
  };

  const handleEmkDocumentDownload = async (tourIds: number[]) => {
    const jobData = await CustomsService.createEmkDocuments({ tourIds });

    try {
      const jobObject = await jobService.addJobPromise(jobData.jobId.toString());
      const binaryBuffer = jobObject.returnvalue.file;

      if (binaryBuffer) {
        notifications.addInfo(
          `Emk Dokumente für Touren ${tourIds.join(', ')} wurden erfolgreich erstellt und werden heruntergeladen.`,
        );
        const zipBuffer = Buffer.from(binaryBuffer, 'binary');
        fileDownload(zipBuffer, getJobResultFileName(jobObject), `application/zip`);
      } else {
        notifications.addError(`Dokument aus Job ${jobData?.jobId} konnte nicht heruntergeladen werden`);
      }
    } catch (error) {
      if (error instanceof JobError) {
        notifications.addError(
          `Fehler beim Erstellen der Emk Dokumente für die Touren ${tourIds.join(', ')}: ${
            error.getJobState().failedReason
          }`,
        );
      } else {
        notifications.addError(error);
      }
    }
  };

  const handleCreateConsignments = async (
    tourIds: number[],
    consignmentType: ConsignmentType,
    additionalConsignmentData: IAdditionalConsignmentData = {},
  ) => {
    try {
      const { jobId } = await CustomsService.createConsignments(tourIds, consignmentType, additionalConsignmentData);

      waitForConsignmentJob(jobId, tourIds);
    } catch (error) {
      notifications.addError(error);
    } finally {
      await mutate(TourService.toursByBatchMutatorFactory(consignmentType, tourIds));
    }
  };

  const handleCreateDeImportCsv = async (tourBatchId: string) => {
    try {
      const response = await TourService.getDeImportCsv(tourBatchId);

      const filename = `${tourBatchId}.csv`;

      fileDownload(new Blob([response]), filename, 'text/csv');
    } catch (error) {
      notifications.addError(error);
    }
  };

  const handleCreateDeBatchImportPositionsCsv = async (tourBatchId: string) => {
    try {
      const response = await TourService.getDeBatchImportPositionsCsv(tourBatchId);

      const filename = `${tourBatchId}_positions.csv`;

      fileDownload(new Blob([response]), filename, 'text/csv');
    } catch (error) {
      notifications.addError(error);
    }
  };

  const handleCreateCollectiveReference = async (tourIds: number[]) => {
    const jobData = await CustomsService.createCollectiveReferences(tourIds);

    try {
      const jobObject = await jobService.addJobPromise(jobData.jobId.toString());
      const pdfBuffer = jobObject.returnvalue.file;

      if (pdfBuffer) {
        notifications.addSuccess(
          `Sammelbezugsscheine für Touren ${tourIds.join(
            ', ',
          )} wurden erfolgreich erstellt und werden heruntergeladen.`,
        );
        pdfFileDownload(pdfBuffer, getJobResultFileName(jobObject));
      } else {
        notifications.addError(`Dokument aus Job ${jobData.jobId} konnte nicht heruntergeladen werden`);
      }

      await mutate();
    } catch (error) {
      if (error instanceof JobError) {
        notifications.addError(
          `Fehler beim Erstellen der Sammelbezugsscheine für die Touren ${tourIds.join(', ')}: ${
            error.getJobState().failedReason
          }`,
        );
      }
    }
  };

  const handleCreateTransitDepartureDocument = async (tourIds: number[]) => {
    const jobData = await CustomsService.createTransitDepartureDocument(tourIds);

    try {
      const jobObject = await jobService.addJobPromise(jobData.jobId.toString());
      const pdfBuffer = jobObject.returnvalue.file;

      if (pdfBuffer) {
        notifications.addSuccess(
          `Transit-Ausgang-Dokument für Touren ${tourIds.join(
            ', ',
          )} wurde erfolgreich erstellt und wird heruntergeladen.`,
        );
        pdfFileDownload(pdfBuffer, getJobResultFileName(jobObject));
      } else {
        notifications.addError(`Dokument aus Job ${jobData.jobId} konnte nicht heruntergeladen werden`);
      }

      await mutate();
    } catch (error) {
      if (error instanceof JobError) {
        notifications.addError(
          `Fehler beim Erstellen des Transit-Ausgang-Dokuments für die Touren ${tourIds.join(', ')}: ${
            error.getJobState().failedReason
          }`,
        );
      }
    }
  };

  const handleCreatePostNordCsv = async (tourBatchId: string) => {
    const jobData = await CustomsService.createPostNordCsv({ tourBatchId });

    try {
      const jobObject = await jobService.addJobPromise(jobData.jobId.toString(), (_, { progress }) =>
        setPostNordZipProgress(progress * 100),
      );
      const binaryBuffer = jobObject.returnvalue.file;

      if (binaryBuffer) {
        notifications.addSuccess(
          `Post Nord CSV für Tour Batch ${tourBatchId} wurde erfolgreich erstellt und wird heruntergeladen.`,
        );
        const zipBuffer = Buffer.from(binaryBuffer, 'binary');
        fileDownload(zipBuffer, getJobResultFileName(jobObject), `application/zip`);
      } else {
        notifications.addError(`Dokument aus Job ${jobData.jobId} konnte nicht heruntergeladen werden`);
      }

      await mutate();
    } catch (error) {
      if (error instanceof JobError) {
        notifications.addError(
          `Fehler beim Erstellen des Post Nord CSV für Tour Batch ${tourBatchId}: ${error.getJobState().failedReason}`,
        );
      } else {
        notifications.addError(error);
      }
    } finally {
      setPostNordZipProgress(0);
    }
  };

  if (isLoading) {
    return (
      <>
        <Skeleton
          width={120}
          height={50}
        />
        <Skeleton
          width={180}
          height={50}
        />
        <a
          href="https://www.youtube.com/watch?v=0Wi8Fv0AJA4"
          target="_blank"
          rel="noreferrer"
        >
          <Skeleton
            width={160}
            height={50}
          />
        </a>
      </>
    );
  }

  return (
    <>
      <CreateConsignmentDialog
        tourDispatchCountry={tourDispatchCountry}
        onClose={() => setDialog(null)}
        open={!!dialog}
        notes={batch.notes}
        tourIds={dialog?.tourIds}
        consignmentType={dialog?.consignmentType}
        createConsignments={async (additionalConsignmentData) =>
          dialog
            ? handleCreateConsignments(dialog.tourIds, dialog.consignmentType, additionalConsignmentData)
            : Promise.reject('Dialog closed')
        }
      />

      {deImportTourIds && showDeImportModal && (
        <DeImportDocumentsDialog
          tourType={type}
          isSingleTour={isSingleTour}
          tourIds={deImportTourIds}
          onClose={() => setShowDeImportModal(false)}
        />
      )}

      {exportTourIds && !isReturnFromNorway && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<SendIcon />}
          disabled={!exportTourIds.length}
          onClick={() => openDialog(exportTourIds, ConsignmentType.EXPORT)}
          title="Export-Consignments"
        >
          Export
        </Button>
      )}

      {exportGvmsTourIds && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<SendIcon />}
          disabled={!exportGvmsTourIds.length}
          onClick={() => openDialog(exportGvmsTourIds, ConsignmentType.EXPORT_GVMS)}
          title="Export-GVMS-Consignment"
        >
          Export-GVMS
        </Button>
      )}

      {transitManifestTourIds && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<LibraryBooksIcon />}
          disabled={!transitManifestTourIds.length}
          onClick={() => openDialog(transitManifestTourIds, ConsignmentType.TRANSIT_MANIFEST)}
          title="Transit-Manifest-Consignment"
        >
          Transit-Manifest
        </Button>
      )}

      {transitTourIds && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<LibraryBooksIcon />}
          disabled={!transitTourIds.length}
          onClick={() => openDialog(transitTourIds, ConsignmentType.TRANSIT)}
          title="Transit-Consignment"
        >
          Transit
        </Button>
      )}

      {importTourIds && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<SendIcon />}
          disabled={!importTourIds.length}
          onClick={() =>
            openDialog(
              importTourIds,
              batch.destinationCountry === IsoCountryCode.GB && type === TourType.RETOUR
                ? ConsignmentType.IMPORT_DE_RETURN_BATCH
                : isNorwayProcessType
                  ? !isReturnFromNorway
                    ? ConsignmentType.POST_NORD_IMPORT
                    : ConsignmentType.IMPORT_DE_RETURN_BATCH
                  : ConsignmentType.IMPORT,
            )
          }
          title="Import-Consignments"
        >
          Import
        </Button>
      )}

      {transitDepartureTourIds && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<LibraryBooksIcon />}
          disabled={!transitDepartureTourIds.length}
          onClick={() => {
            openDialog(transitDepartureTourIds, ConsignmentType.TRANSIT_DEPARTURE);
          }}
          title="Transit-Ausgang-Consignments"
        >
          Transit-Ausgang
        </Button>
      )}

      {ensTourIds && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<LibraryBooksIcon />}
          disabled={!ensTourIds.length}
          onClick={() => {
            openDialog(ensTourIds, ConsignmentType.ENS);
          }}
          title="ENS-Consignments"
        >
          ENS
        </Button>
      )}

      {importGvmsTourIds && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<SendIcon />}
          disabled={!importGvmsTourIds.length}
          onClick={() => openDialog(importGvmsTourIds, ConsignmentType.IMPORT_GVMS)}
          title="Import-GVMS-Consignment"
        >
          Import-GVMS
        </Button>
      )}

      {transitArrivalTourIds && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<LibraryBooksIcon />}
          disabled={!transitArrivalTourIds.length}
          onClick={() => {
            openDialog(transitArrivalTourIds, ConsignmentType.TRANSIT_ARRIVAL);
          }}
          title="Transit-Eingang-Consignments"
        >
          Transit-Eingang
        </Button>
      )}

      {sumATourIds && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<LibraryBooksIcon />}
          disabled={!sumATourIds.length}
          onClick={() => openDialog(sumATourIds, ConsignmentType.SUMA)}
          title="SumA-Consignment"
        >
          SumA
        </Button>
      )}
      {tourIdsWithEmk && !isNorwayProcessType && (
        <ButtonAsync
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<DiamondIcon />}
          disabled={!tourIdsWithEmk.length}
          onClick={() => handleEmkDocumentDownload(tourIdsWithEmk)}
        >
          EMK Dokumente
        </ButtonAsync>
      )}

      {deImportTourIds && (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<KeyboardReturnIcon />}
          onClick={() => setShowDeImportModal(true)}
          disabled={!deImportTourIds?.length}
        >
          DE Import Dokumente
        </Button>
      )}

      {!isSingleTour && deImportTourIds && tourBatchId && (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<KeyboardReturnIcon />}
          onClick={() => handleCreateDeImportCsv(tourBatchId)}
          disabled={!deImportTourIds?.length}
        >
          DE Import CSV
        </Button>
      )}

      {!isSingleTour && deImportTourIds && tourBatchId && (isReturnFromGb || isReturnFromNorway) && (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<KeyboardReturnIcon />}
          onClick={() => handleCreateDeBatchImportPositionsCsv(tourBatchId)}
          disabled={!deImportTourIds?.length}
        >
          {t('Position Details Return')}
        </Button>
      )}

      {tourIdsForCollectiveReference && (
        <ButtonAsync
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<GavelIcon />}
          disabled={!tourIdsForCollectiveReference.length}
          onClick={() => handleCreateCollectiveReference(tourIdsForCollectiveReference)}
        >
          Sammelbezugsschein
        </ButtonAsync>
      )}

      {tourIdsForTransitDepartureDocument && (
        <ButtonAsync
          variant="contained"
          color="secondary"
          size="small"
          startIcon={<GavelIcon />}
          disabled={!tourIdsForTransitDepartureDocument.length}
          onClick={() => handleCreateTransitDepartureDocument(tourIdsForTransitDepartureDocument)}
        >
          Transit-Ausgang-Liste
        </ButtonAsync>
      )}

      {!!data[0]?.tour.process &&
        !ProcessService.isProcessUsingVOEC(data[0].tour.process) &&
        isNorwayProcessType &&
        !isReturnFromNorway &&
        tourBatchId &&
        !isSingleTour && (
          <ButtonAsync
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<GavelIcon />}
            onClick={() => handleCreatePostNordCsv(tourBatchId)}
          >
            Post-Nord ZIP{postNordZipProgress > 0 ? ` (${postNordZipProgress}%)` : undefined}
          </ButtonAsync>
        )}

      {!isSingleTour && tourBatchId && type === TourType.TOUR && (
        <>
          <ButtonAsync
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<GavelIcon />}
            onClick={() => handleCreateTourBatchInvoices(tourBatchId)}
          >
            Batch Invoice{batchInvoiceProgress > 0 ? ` (${batchInvoiceProgress}%)` : undefined}
          </ButtonAsync>
        </>
      )}
    </>
  );
};

export default CustomsActions;
